@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
}
@page {
  margin: 15mm 15mm 15mm 15mm;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'BuenosAires';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  scrollbar-width: thin;
}

#root {
  height: 100%;
  min-height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  touch-action: manipulation !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-padding {
  padding: 0 !important;
}
.padding-top-bottom-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
form {
  padding-top: 12px;
  padding-bottom: 12px;
}
.FormGroup {
  margin-top: 20px !important;
  padding-top: 10px !important;
  margin-bottom: 20px !important;
  padding-bottom: 10px !important;
  width: auto;
}
.FormGroupFull {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.display-block {
  display: block !important;
}

.slide-right-enter {
  transform: translate(-100%);
}
.slide-right-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}
.slide-right-exit {
  transform: translate(-100%);
}
.slide-right-exit-active {
  transform: translate(-100%);
}
h4 {
  font-size: 18px !important;
}
.MuiStep-horizontal {
  padding: 0 !important;
}
