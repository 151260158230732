.Navbar {
  background: transparent !important;
  box-shadow: none !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-top: 100px;
}
.Toolbar {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
}
.Navbar .HomeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 12px;
}
.grow {
  flex-grow: 1;
}
.MenuItem {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
